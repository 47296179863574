import { useAuthStore } from '~/store/auth'
import { useGlobalStore } from '~/store/global'

export default defineNuxtRouteMiddleware( async (to: any, from: any) => {
  const router = useRouter()
  const authStore = useAuthStore()
  const userCookie = useCookie('user')
  const tokenCookie = useCookie('token')
  const globalStore = useGlobalStore()

  // 회원
  if (userCookie.value && tokenCookie.value) {

    // 소셜 인증을 할 땐 미들웨어 적용을 하지 않는다.
    // 단, 로그인이 아닌 개인정보 수정인 경우에만 하지 않는다.
    let socialType = ''
    if (to.query?.state) {
      socialType = to.query.state.split('-')[0] === 'modify' ? 'modify' : 'login'
    }

    // 로그인 상태에서 비로그인 관련 무언가를 한다면 홈으로 보낸다.
    // 예를 들어 로그인, 회원가입, 아이디 찾기 등
    if (globalVariables.hook.auth.includes((to?.name)) && socialType != 'modify') {
      if (process.client) {
        return router.push({ path: '/', query: { error: Date.now() } })
      }
    }

  // 비회원
  } else {
    
    // 강제 로그아웃 ( 찌꺼기 제거 )
    authStore.clearUser()

    // 토큰 없이 개인정보가 필요한 페이지도 접근 한다면 홈으로 보낸다.
    if (globalVariables.hook.private.includes((to?.name))) {
      if (process.client) {
        globalStore.setRouterLoader(false)
        globalStore.setRouterPage(false)
        globalStore.setLoader(false)

        // 알림 후 이동
        const $i18n: any = globalVariables.app.i18n
        globalFunctions.alert($i18n.$t('alert-default'), $i18n.$t('auth-need-login'), $i18n.$t('close-btn'))
        return router.push({ path: '/', query: { error: Date.now() } })
      }

    // 로그인 혹은 회원 가입 페이지로 접근한다면 페이지를 기록해준다.
    } else if (globalVariables.hook.save.includes((to?.name)) && !globalVariables.hook.auth.includes((from?.name as string))) {
      if (to?.name != from?.name) {
        authStore.updateHistory(to, from)
      }
    }

  }

  // 기본 리턴
  return

})